@import "../partials/vars";

.community {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--textColor);
	color: var(--background);
	backdrop-filter: blur(12px);
	padding: 100px 20px;
	text-align: center;
	position: relative; 
}

.community__monkey {
	width: 300px;
	height: auto;
	position: absolute;
	right: 0;
	bottom: -10px;
	
}

@media screen and (max-width: 768px) {
	.community {
		padding-bottom: 150px;
		position: relative;
	}

	.community__monkey {
		position: absolute;
		right: 50%;
		transform: translateX(50%);
		bottom: -10px; 
		
	}

}