@import "../../scss/partials/vars.scss";

.projects{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    
    .project__inner{
        width: 1200px;
        margin: auto;
        max-width: 100%;
    }
    .project__title{
        margin-left: 10px;
        @media(max-width: 800px){
            font-size: 1.5em;
        }
    }
    .projects__menu{
        width: 1200px;
        margin: 20px auto;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: none;
        padding: 4px 0px;
        border-top: 1px solid RGBA(var(--textColor-rgb) / 10%);
        border-bottom: 1px solid RGBA(var(--textColor-rgb) / 10%);
        padding-bottom: 5px;
        flex-wrap: nowrap;
        
        .tabs__item{
            min-width: 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: -5px;
        }
    }

    .timeline__label{
        width: 150px;;
    }
    #cd-timeline {
        position: relative;
        padding: 2em 0;
        width: 1200px;
        margin: auto;
        margin-top: -5px !important;
        margin-bottom: -5px !important;
    }
    #cd-timeline::before {
        content: '';
        position: absolute;
        top: 0;
        left: 25px;
        height: 100%;
        width: 4px;
        background: RGBA(var(--primary-rgb) / 80%);
    }
    @media only screen and (min-width: 1170px) {
        #cd-timeline {
            margin-top: 3em;
            margin-bottom: 3em;
        }
        #cd-timeline::before {
            left: 50%;
            margin-left: -2px;
        }
    }
    
    .cd-timeline-block {
        position: relative;
        margin: 2em 0;
    }
    .cd-timeline-block:after {
        content: "";
        display: table;
        clear: both;
    }
    .cd-timeline-block:first-child {
        margin-top: 0;
    }
    .cd-timeline-block:last-child {
        margin-bottom: 0;
    }
    @media only screen and (min-width: 1170px) {
        .cd-timeline-block {
            margin: 4em 0;
        }
        .cd-timeline-block:first-child {
            margin-top: 0;
        }
        .cd-timeline-block:last-child {
            margin-bottom: 0;
        }
    }
    
    .cd-timeline-img {
        position: absolute;
        top: 8px;
        left: 12px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        box-shadow: 0 0 0 4px RGBA(var(--primary-rgb) / 90%), inset 0 2px 0 RGBA(var(--primary-rgb) / 60%), 0 3px 0 4px RGBA(var(--primary-rgb) / 20%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 1.5rem;
    }
    .cd-timeline-img {
        background: RGBA(var(--primary-rgb) / 50%);
    }
    @media only screen and (min-width: 1170px) {
        .cd-timeline-img {
            width: 30px;
            height: 30px;
            left: 50%;
            margin-left: -15px;
            margin-top: 15px;
        }
    }
    
    .cd-timeline-content {
        position: relative;
        margin-left: 60px;
        margin-right: 30px;
        background: var(--light);
        border-radius: 2px;
        padding: 1em;
        border: 1px solid RGBA(var(--primary-rgb) / 20%);
    }
    .cd-timeline-content::before {
        content: '';
        position: absolute;
        top: 16px;
        right: 100%;
        height: 0;
        width: 0;
        border: 7px solid transparent;
        border-right: 7px solid var(--primary);
    }
    
    @media only screen and (min-width: 1170px) {
        .cd-timeline-content {
            margin-left: 0;
            width: 42%;
            margin: 0 5%;
        }
        .cd-timeline-content::before {
            top: 24px;
            left: 100%;
            border-color: transparent;
            border-left-color: var(--light);
        }
        .cd-timeline-block:nth-child(even) .cd-timeline-content {
            float: right;    
        }
        .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
            top: 24px;
            left: auto;
            right: 100%;
            border-color: transparent;
            border-right-color: var(--light);
        }
    }
    
    .projects__board{
        width: 1200px;
        margin: 20px auto;
        max-width: 100%;
        column-gap: 1rem;
        
        .projects__board-item{
            margin: 3px;
            border-radius: 1px;
            overflow: hidden;
            backdrop-filter: blur(12px);
            background-color: var(--secondary);
            border: 1px solid RGBA(var(--textColor-rgb) / 10%);
            width: 32%;
            margin: 3px;
            display: inline-block;
            width: 100%;
            
            .projects__board-itemHeader{
                display: flex;
                flex-direction: row;
                padding-bottom: 3px;
                border-bottom: 1px solid RGBA(var(--textColor-rgb) / 10%);
                align-items: center;
                position: relative;
                font-weight: bold;
                
                .projects__board-itemHeaderControl{
                    position: absolute;
                    right: -5px;
                    top: -8px;
                    display: flex;
                    flex-direction: row;
                    border-radius: 50%;
                    overflow: hidden;
                    
                    .material-icons{
                        cursor: pointer;
                        display: none;
                    }
                }
                
                .projects__board-itemControl{
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: var(--background);
                    margin-right: 5px;
                }
                .projects__board-itemHeaderText{
                    max-width: 90%;
                    border-left: 1px solid RGBA(var(--primary-rgb) / 50%);
                    padding: 10px 10px;
                    margin-left: -15px;
                    margin-top: -15px;
                    min-height: 30px;
                }
            }
            .projects__board-itemBody {
                margin-top: 10px;
                margin-bottom: 30px;
                
                .projects__board-itemBodyCodeInner{
                    overflow-x: auto;
                    padding: 10px;
                    border-radius: var(--radius);
                }
            }
            .projects__board-itemFooter {
                position: absolute;
                bottom: 0px;
                left: 0px;
                opacity: 0;
                transition-delay: 0.1s;
                transition-duration: 0.1s;
                transition-property: opacity;
            }
            &:hover{
                border-color: RGBA(var(--primary-rgb) / 20%);
                
                .projects__board-itemFooter{
                    opacity: 1;
                }
                .material-icons{
                    display: block !important;
                }
            }
        }
        @media(max-width: 1245px){
            padding: 20px;
        }
        @media(max-width: 955px){
            columns: 2 !important;
        }
        @media(max-width: 700px){
            columns: 1 !important;
        }
    }
    @media(max-width: 955px){
        .layout__selector{
            display: none;
        }
    }
}
.modal__project{
    .modal{
        min-height: 650px;
        width: 880px;
        background: linear-gradient(-45deg, RGBA(var(--primary-rgb) / 60%),  var(--light), RGBA(var(--primary-rgb) / 40%));
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
    }
    .projects__board-itemHeader{
        border-left: 4px solid RGBA(var(--primary-rgb) / 50%);
        padding: 10px;
    }
    .projects__board-itemHeaderControl{
        .material-icons{
            display: none;
        }
    }	
    .projects__board-itemHeaderText{
        font-size: 20px !important;
    }
    .projects__board-itemBodyCodeInner{
        padding: 15px;
        word-wrap: break-word !important;
        word-break: break-all;
        overflow: hidden;
    }
    .modal__project-download{
        position: absolute;
        top: 25px;
        left: 25px;
        cursor: pointer;
        
        &:hover{
            opacity: 0.5;
        }
    }
    .cd-timeline-content{
        width: 100%;
        margin: 0 0%;
    }
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}