@import "vars";
.menu{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    background: var(--primary);
    position: relative;
}
.menu__static{
    position: fixed;
}
.menu__top{
    top: 0;
    left: 0;
}
.menu__bottom{
    bottom: 0;
    left: 0;
}
.menu__left{
    width: auto;
    height: 100%;
    flex-direction: column;
    left: 0;
    top: 0;
}
.menu__right{
    width: auto;
    height: 100%;
    flex-direction: column;
    right: 0;
    top: 0;
}
.menu__bordered{
    border-bottom: 1px solid RGBA(var(--textColor-rgb) / 10%);
}
.menu__sidebar{
    flex-basis: 150px;
    display: flex;
    flex-direction: column;
}