@import "vars";
.loader__full{
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	background-color: RGBA(var(--background-rgb) / 10%);
	backdrop-filter: blur(5px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 101;
}
.loader {
	transform: rotateZ(45deg);
	perspective: 1000px;
	border-radius: 50%;
	width: 48px;
	height: 48px;
	color: var(--primary);
}
.loader:before,
.loader:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: inherit;
	height: inherit;
	border-radius: 50%;
	transform: rotateX(70deg);
	animation: 1s spin linear infinite;
}
.loader:after {
	color: var(--textColor);
	transform: rotateY(70deg);
	animation-delay: .4s;
}

@keyframes rotate {
	0% {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotateZ(360deg);
	}
}

@keyframes rotateccw {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(-360deg);
	}
}

@keyframes spin {
	0%,
	100% {
		box-shadow: .2em 0px 0 0px currentcolor;
	}
	12% {
		box-shadow: .2em .2em 0 0 currentcolor;
	}
	25% {
		box-shadow: 0 .2em 0 0px currentcolor;
	}
	37% {
		box-shadow: -.2em .2em 0 0 currentcolor;
	}
	50% {
		box-shadow: -.2em 0 0 0 currentcolor;
	}
	62% {
		box-shadow: -.2em -.2em 0 0 currentcolor;
	}
	75% {
		box-shadow: 0px -.2em 0 0 currentcolor;
	}
	87% {
		box-shadow: .2em -.2em 0 0 currentcolor;
	}
}



.btn__loader{
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	left: 0;
	top: 0;
	background-color: RGBA(var(--background-rgb) / 30%);
	width: 100%;
	height: 100%;
	
	/* HTML: <div class="loader"></div> */
	.ld {
		width: 20px;
		aspect-ratio: 1;
		border-radius: 50%;
		border: 2px solid;
		border-color: var(--primary) var(--background);
		animation: l1 1s infinite;
	}
	@keyframes l1 {to{transform: rotate(.5turn)}}
}