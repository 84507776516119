@import 'vars';

input[type=checkbox],
input[type=radio] {
	--focus: 2px RGBA(39, 94, 254, .3);
	--border: #BBC1E1;
	--border-hover: #275EFE;
	-webkit-appearance: none;
	background: RGB( var(--textColor-rgb) / 15%);
	-moz-appearance: none;
	height: 21px;
	outline: none;
	display: inline-block;
	vertical-align: top;
	position: relative;
	margin: 0;
	cursor: pointer;
	border: 1px solid var();
	transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}
input[type=checkbox]:after,
input[type=radio]:after {
	content: "";
	display: block;
	left: 0;
	top: 0;
	position: absolute;
	transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
}
input[type=checkbox]:checked,
input[type=radio]:checked {
	background: var(--primary);
	border-color: RGBA(var(--primary-rgb)  40%);
	--d-t: .6s;
	--d-t-e: cubic-bezier(.2, .85, .32, 1.2);
}
input[type=checkbox]:disabled,
input[type=radio]:disabled {
	background: RGB(var(--textColor-rgb) / 50%);
	cursor: not-allowed;
	opacity: 0.9;
}
input[type=checkbox]:disabled:checked,
input[type=radio]:disabled:checked {
	background: var(--textColor);
	border-color: RGB(var(--textColor-rgb) /  150%);
	--bc: var(--border);
}
input[type=checkbox]:disabled + label,
input[type=radio]:disabled + label {
	cursor: not-allowed;
}
input[type=checkbox]:hover:not(:checked):not(:disabled),
input[type=radio]:hover:not(:checked):not(:disabled) {
	--bc: var(--border-hover);
}
input[type=checkbox]:focus,
input[type=radio]:focus {
	box-shadow: 0 0 0 var(--focus);
}
input[type=checkbox]:not(.switch),
input[type=radio]:not(.switch) {
	width: 21px;
}
input[type=checkbox]:not(.switch):after,
input[type=radio]:not(.switch):after {
	opacity: var(--o, 0);
}
input[type=checkbox]:not(.switch):checked,
input[type=radio]:not(.switch):checked {
	--o: 1;
}
input[type=checkbox] + label,
input[type=radio] + label {
	font-size: 14px;
	line-height: 21px;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	margin-left: 4px;
}

input[type=checkbox]:not(.switch) {
	border-radius: var(--radius);
}
input[type=checkbox]:not(.switch):after {
	width: 7px;
	height: 12px;
	border: 2px solid var(--textColor);
	border-top: 0;
	border-left: 0;
	left: 7px;
	top: 3px;
	transform: rotate(var(--r, 20deg));
}
input[type=checkbox]:not(.switch):checked {
	--r: 43deg;
}
input[type=checkbox].switch {
	width: 38px;
	border-radius: 11px;
}
input[type=checkbox].switch:after {
	left: 2px;
	top: 3px;
	border-radius: 50%;
	width: 15px;
	height: 15px;
	background: var(--textColor);
	transform: translateX(var(--x, 0));
}
input[type=checkbox].switch:checked {
	--x: 17px;
	box-shadow: 3 0 0 var(--focus);
}
input[type=checkbox].switch:disabled:not(:checked):after {
	opacity: 0.6;
}
.md__input-lg input[type=checkbox].switch {
	width: 50px;
	border-radius: 15px;
	height: 29px;

}
.md__input-lg input[type=checkbox].switch:after {
	left: 7px;
	top: 3px;
	width: 22px;
	height: 22px;
}
input[type=radio] {
	border-radius: 50%;
}
input[type=radio]:after {
	width: 24px;
	height: 24px;
	left: -1.5px;
	top: -1px;
	border-radius: 50%;
	background: var(--textColor);
	box-shadow: 0px 3px 6px 1px RGB(38 107 193 / 8%);
	opacity: 0;
	transform: scale(var(--s, 0.7));
}
input[type=radio]:checked {
	--s: .5;
}
input:disabled{
	opacity: 0.5;
	pointer-events: none;
	cursor: not-allowed;
}
input {
    outline: none;
    border: none
}
textarea {
    outline: none;
    border: none
}
textarea:focus,input:focus {
    border-color: transparent!important
}
input:focus::-webkit-input-placeholder {
    color: transparent
}
input:focus:-moz-placeholder {
    color: transparent
}
input:focus::-moz-placeholder {
    color: transparent
}
input:focus:-ms-input-placeholder {
    color: transparent
}
textarea:focus::-webkit-input-placeholder {
    color: transparent
}
textarea:focus:-moz-placeholder {
    color: transparent
}
textarea:focus::-moz-placeholder {
    color: transparent
}
textarea:focus:-ms-input-placeholder {
    color: transparent
}
input::-webkit-input-placeholder {
    color: RGBA(var(--textColor-rgb)/40%)
}
input:-moz-placeholder {
    color: RGBA(var(--textColor-rgb)/40%)
}
input::-moz-placeholder {
    color: RGBA(var(--textColor-rgb)/40%)
}
input:-ms-input-placeholder {
    color: RGBA(var(--textColor-rgb)/40%)
}
textarea::-webkit-input-placeholder {
    color: RGBA(var(--textColor-rgb)/40%)
}
textarea:-moz-placeholder {
    color: RGBA(var(--textColor-rgb)/40%)
}
textarea::-moz-placeholder {
    color: RGBA(var(--textColor-rgb)/40%)
}
textarea:-ms-input-placeholder {
    color: RGBA(var(--textColor-rgb)/40%)
}
.input__wrap{
    width: 100%;
    position: relative;
    border: 1px solid RGB(var(--textColor-rgb) / 20%);
    border-radius: var(--radius);

	label {
		font-size: 14px;
		color: RGB(var(--textColor-rgb) /  80%);
		line-height: 1.5;
		text-transform: uppercase;
		width: 100%;
		padding-bottom: 11px
	}

	.focus {
		position: absolute;
		display: block;
		width: calc(100% + 2px);
		height: calc(100% + 2px);
		top: -1px;
		left: -1px;
		pointer-events: none;
		border: 1px solid;
		border-color: var(--primary);
		visibility: hidden;
		opacity: 0;
		-webkit-transition: all .4s;
		-o-transition: all .4s;
		-moz-transition: all .4s;
		transition: all .4s;
		-webkit-transform: scaleX(1.1) scaleY(1.3);
		-moz-transform: scaleX(1.1) scaleY(1.3);
		-ms-transform: scaleX(1.1) scaleY(1.3);
		-o-transform: scaleX(1.1) scaleY(1.3);
		transform: scaleX(1.1) scaleY(1.3)
	}
	input{
		display: block;
		width: 100%;
		background-color: RGBA(var(--background-rgb) / 20%);
		color: RGB(var(--textColor-rgb) /  80%);
		border-color: RGBA(var(--textColor-rgb) / 20%);
		line-height: 1.2;
		padding: 0 25px;
	}
	input:focus+.focus {
		visibility: visible;
		opacity: 1;
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1)
	}
	input[type=text], input[type=password], input[type=email], input[type=number], input[type=date], input[type=tel]{
		height: 45px
	}
	textarea {
		font-family: var(--fontFamily);
		padding: 15px 20px;
		display: block;
		width: 100%;
		background: 0 0;
		color: RGB(var(--textColor-rgb) /  80%);
		border-color: RGBA(var(--textColor-rgb) / 20%);
		line-height: 1.2;
	}
	textarea:focus+.focus {
		visibility: visible;
		opacity: 1;
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1)
	}
}
select {
	appearance: none;
	border: 0;
	outline: 0;
	font: inherit;
	/* Personalize */
	min-width: 150px;
	height: 45px;
	padding: 0 4em 0 1em;
	background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg)
	no-repeat right 0.8em center / 1.4em,
	linear-gradient(to left, RGBA(var(--secondary-rgb) / 20%) 3em, RGBA(var(--secondary-rgb) / 30%) 3em);
	border: 1px solid RGBA(var(--textColor-rgb) / 10%);
	color: var(--textColor);
	border-radius: 0.25em;
	//box-shadow: 0 0 1em 0 RGBA(var(--textColor-rgb) / 2%);
	cursor: pointer;
	option {
		color: inherit;
		background-color: var(--secondary);
		padding: 15px 20px;
	}
	/* Remove focus outline */
	&:focus {
		outline: none;
	}
	/* Remove IE arrow */
	&::-ms-expand {
		display: none;
	}
}
.input__upload{
	width: 100%;
	border: 2px dashed RGB(var(--textColor-rgb) / 30%);
	padding: 10px;
	border-radius: var(--radius);
	text-align: center;
	position: relative;
	height: 100%;
	input{
		opacity: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 99;
		margin: 0;
		padding: 0;
		display: block;
		cursor: pointer;
		width: 100%;
	}

	&:hover{
		border: 2px dashed RGB(var(--textColor-rgb) / 80%);
		background: var(--secondary);
	}
}
.select__100{
	width: 100%;
}
.input__error{
	border-color: var(--danger) !important;
}
.form__group{
	margin: 10px 0px;
}
.input__rounded{
	input{
		border-radius: 20px;
	}
}