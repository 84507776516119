@import "vars";

.navigation{

	.navigation__toggle{
		position: absolute;
		left: 20px;
		bottom: 20px;
		background-color: RGBA(var(--primary-rgb) / 80%);
		border: 1px solid var(--textColor);
		box-shadow: 0px 5px 15px RGBA(var(--primary-rgb) / 20%);
		transition: all 0.3s ease 0s;
		z-index: 11;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.navigation__menu{
		display: flex;
		flex-direction: column;
		position: absolute;
		left: 20px;
		top: 90px;
		height: 82%;
		background-color: var(--secondary);
		z-index: 10;
		max-width: 300px;
		width: 300px;
		box-shadow: 0px 8px 12px RGBA(var(--textColor-rgb) / 4%);
		padding: 15px;
		border-radius: 15px;
		//border: 1px dashed RGBA(var(--primary-rgb) / 20%);
		overflow-y: auto;
		overflow-x: hidden;

		.navigation__menu-item{
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: 5px 10px;
			background-color: var(--secondary);
			margin: 2px;
			border-radius: 6px;
			font-size: 13px;
			border: 1px solid transparent;
			cursor: pointer;
			position: relative;

			.btn__arrow{
				margin: 0px;
				margin-left: auto;
				width: 35px;
				height: 35px;
				border-radius: 50%;
				display: flex;
				flex-direction: column;
				text-align: center;
				justify-content: center;
				padding: 3px;
			}
			&:hover{
				//background-color: var(--background);
				border: 1px solid RGBA(var(--primary-rgb) / 10%);
			}
			.navigation__menu-itemPill{
				position: absolute;
				font-size: 9px;
				right: 2px;
				bottom: 2px;
				background-image: linear-gradient(45deg, RGBA(var(--primary-rgb) / 30%) 25%, var(--background) 25%, var(--background) 50%, RGBA(var(--primary-rgb) / 30%) 50%, RGBA(var(--primary-rgb) / 30%) 75%, var(--background) 75%, var(--background) 100%);
				background-size: 42.43px 42.43px;
				padding: 2px 5px;
				border-radius: var(--radius);
				font-weight: bold;
				border:RGBA(var(--textColor-rgb) / 10%);
				color: var(--textColor);
			}
		}
		.navigation__menu-itemActive{
			border-left: 1px solid var(--primary);
			border-radius: 0px;
			color:var(--primary) !important;
			background-color: RGBA(var(--primary-rgb) / 10%);
		}
	}
	
}