@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https: //fonts.googleapis.com/icon?family=Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/css2?family=Monoton&family=Poppins&display=swap');
@import "partials/vars";
@import "partials/buttons";
@import "partials/forms";
@import "partials/typography";
@import "partials/helpers";
@import "partials/table";
@import "partials/misc";
@import "partials/dropdown";
@import "partials/tabs";
@import "partials/modal";
@import "partials/loader";
@import "partials/cards";
@import "partials/menu";

* {
	box-sizing: border-box;
}

html {
	height: 100%;
	width: 100%;
	overflow: hidden;
	font-size: 15px;
	scroll-behavior: smooth;

	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}
	
	/* Handle (the draggable part) */
	::-webkit-scrollbar-thumb {
		background-color: RGBA(var(--textColor-rgb) / 80%);
		border-radius: 6px;
	}
	
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background-color: var(--textColor);
	}
	
	/* Track (the area on which the handle moves) */
	::-webkit-scrollbar-track {
		background-color: var(--background);
	}
	
	/* Track on hover */
	::-webkit-scrollbar-track:hover {
		background-color: var(--secondary);
	}
}

body {
	font-family: var(--fontFamily);
	font-size: 18px;
	display: flex;
	flex-direction: column;
	background: var(--background);
	color: var(--textColor);
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0rem;
	margin: 0rem;
	font-weight: normal;
}

span {
	display: inline-block;
}

a {
	text-decoration: none;
}

.main{
	width: 1200px;
	max-width: 100%;
	margin: auto;

	.main__header{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-top: 40px;

		.main__header-left{
			flex-basis: 50%;
		}
		.main__header-right {
			flex-basis: 50%;

			.main__header-rightLottie{
				width: 100%;
			}
		}
		@media(max-width: 1100px){
			flex-direction: column;

			.main__header-left{
				flex-basis: 100%;
				padding: 20px;
				margin-top: 70px;
				text-align: center;
			}
			.main__header-right {
				flex-basis: 100%;
				padding: 20px;
	
				.main__header-rightLottie{
					width: 650px;
					max-width: 90%;
					margin: auto;
					margin-top: -90px;
				}
			}
		}
	}
	.main__contents{
		display: flex;
		flex-direction: column;
		margin-top: 100px;
		border-top: 1px solid RGBA(var(--textColor-rgb) / 10%);
		position: relative;
		width: 100%;
		padding-top: 30px;
		padding: 20px;
		
		.main__contents-label{
			position: absolute;
			top: -22px;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			width: 200px;
			z-index: 10;
			backdrop-filter: blur(52px);
			pointer-events: none;
		}

		.main__contents-menu{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin-top: 20px;
			justify-content: center;

			.main__contents-card {
				border: none;
				flex-basis: 32%;
				margin: 4px;
				cursor: pointer;
				border: 1px solid RGBA(var(--textColor-rgb) / 5%);
				color: var(--textColor);
				//background-color: var(--secondary);
				background-color: var(--light);
				-webkit-backdrop-filter: blur(12px);
				backdrop-filter: blur(12px);
				min-width: 320px;

				&:hover{
					//background: var(--primary);
					font-weight: bold;
					color: var(--secondaryTextColor);
					background-color: RGBA(var(--primary-rgb)/ 60%);
					-webkit-backdrop-filter: blur(12px);
					backdrop-filter: blur(12px);
				}
			}
			.main__contents-cardUnderConstruction{
				position: absolute;
				font-size: 10px;
				right: 2px;
				bottom: 2px;
				background-image: linear-gradient(45deg, RGBA(var(--primary-rgb) / 30%) 25%, var(--background) 25%, var(--background) 50%, RGBA(var(--primary-rgb) / 30%) 50%, RGBA(var(--primary-rgb) / 30%) 75%, var(--background) 75%, var(--background) 100%);
				background-size: 42.43px 42.43px;
				padding: 2px 5px;
				border-radius: var(--radius);
				font-weight: bold;
				border:RGBA(var(--textColor-rgb) / 10%);
				color: var(--textColor);
			}
		}
	}
}
.logo__text{
	font-family: Monoton;
	color: var(--textColor);
}
.nav{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	background-color: RGBA(var(--background-rgb) / 50%);
	backdrop-filter: blur(12px);

	.nav__inner {
		width: 1200px;
		max-width: 100%;
		display: flex;
		flex-direction: row;
		margin: auto;
	}
}
.logo__grayscale{
	-webkit-filter: grayscale(.6);
}
[data-theme="theme-dark"]{
	// .main__header-rightLottie{
	// 	filter: invert(100%);
	// }
/* 	.newsletter__animation{
		filter: invert(90%);
	} */
}
.contact__email{
	border-radius: 25px;
	overflow: hidden;
	padding: 4px;
	position: relative;

	input{
		height: 100%;
		flex-basis: 60%;
	}
	select{
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 150px;
		z-index:2;
		background-color: var(--background);
		font-size: 12px;
	}
}
.newsletter__wrapper{
	display: flex;
	flex-direction: row;
	
	@media(max-width: 450px){
		flex-direction: column;

		.btn__circle{
			width: 100%;
			border-radius: 25px;
			width: 100%;
		}
	}
}

.cheatsheet__back{
	@media(max-width: 400px){
		span{
			display: none;
		}
	}
}
.gpt__response{
	border: 1px dashed RGBA(var(--textColor-rgb) / 90%);
	padding: 30px 10px;
	position: relative;
	border-radius: 15px;
	margin-top: 20px;

	.gpt__response-header{
		position: absolute;
		left: 0;
		top: 0;
		width: 100px;
		font-size: 11px;
		background-color: var(--textColor);
		color: var(--background);
		padding: 5px 10px;
		border-radius: 20px;

	}

	h1{
		font-size: 1.3em;
	}
	h2{
		font-size: 1.2em;
	}
	h3{
		font-size: 1.1em;
	}
	h4{
		font-size: 1em;
	}
}
[data-theme="theme-dark"]{
	.hljs{
		background-color: transparent;
	}
}
[data-theme="theme-light"]{
	.hljs{
		background-color: var(--textColor);
		//background-color: var(--background);
	}
}
