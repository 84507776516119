@import "../partials/vars";

.footer{
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: RGBA(var(--background-rgb) / 50%);
	backdrop-filter: blur(12px);
	padding: 20px;

	.footer__inner{
		width: 1200px;
		max-width: 100%;
		margin: auto;
	}
	.footer__sub{
		padding: 10px 20px;
		border-top: 1px solid var(--primary);
		border-bottom: 1px solid var(--primary);
		margin: 10px auto;
		width: 150px;
	}
}